<template>
  <v-row dense justify="center">
    <v-col class="text-center" cols="2" v-for="(i, index) in icons" :key="i.name">
      <v-btn icon @click="onIconClicked(index)">
        <v-icon size="48" :color="iconColor(index)">{{i.icon}}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'sentiment-picker',
  props: {
    index: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data: () => ({
    icons: [
      {
        name: 'depressed',
        icon: 'mdi-emoticon-frown-outline',
        color: '#FF1744',
        isEnabled: false
      },
      {
        name: 'tired',
        icon: 'mdi-emoticon-sad-outline',
        color: '#FF6E22',
        isEnabled: false
      },
      {
        name: 'fine',
        icon: 'mdi-emoticon-happy-outline',
        color: '#FFC400',
        isEnabled: false
      },
      {
        name: 'good',
        icon: 'mdi-emoticon-outline',
        color: '#80D53B',
        isEnabled: false
      },
      {
        name: 'awesome',
        icon: 'mdi-emoticon-excited-outline',
        color: '#00E676',
        isEnabled: false
      }
    ]
  }),
  computed: {
    isSelectionMade () {
      return this.icons.filter(i => i.isEnabled).length > 0
    }
  },
  methods: {
    iconColor (index) {
      return this.icons[index].isEnabled ? this.icons[index].color : 'grey'
    },
    onIconClicked (index) {
      for (let i = 0; i < this.icons.length; i++) {
        this.icons[i].isEnabled = false
      }
      this.icons[index].isEnabled = !this.icons[index].isEnabled
      this.$emit('save', index)
    }
  },
  created () {
    if (this.index > -1) {
      this.icons[this.index].isEnabled = true
    }
  }
}
</script>
